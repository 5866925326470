@media (min-width: 1025px) {
  .global_halfWidthDesktop__1awLM {
    width: 50%;
  }
}

.style_select__20foD {
  -webkit-appearance: none;
          appearance: none;
  background-color: white;
  border: 1px solid rgba(62, 15, 72, 1);
  border-radius: 6px;
  color: rgba(62, 15, 72, 1);
  font-size: 18px;
  line-height: 36px;
  padding: 12px 54px 12px 12px;
  width: 100%;
  cursor: pointer;
}

.style_select__20foD input :disabled {
  pointer-events: none;
}


.style_formField__OhSqm {
  padding: 12px;
  background-color: white;
  border: 1px solid rgba(62, 15, 72, 1);
  border-radius: 6px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
}

.style_formField__OhSqm *:disabled {
  pointer-events: none;
}


.search-container {
  display:flex;
  flex:1 1;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.searchbox {
  margin-right:1em;
  flex:2 1;
}

.searchbox-plain {
  flex:2 1;
}

.search-button {
  flex:1 1;
}

/* It would be good to refactor the media query in a more reusable way */
@media screen and (max-width: 30em) {
  .searchbox,
  .searchbox-plain,
  .search-button {
    margin-right:0;
    margin-bottom: 12px;
    flex: 1 1 100%;
    width: 100%;
  }
}


.autocomplete {
  position: relative;
}

.autocomplete-item {
  padding:0.25em;
  font-weight:normal;
  font-size:1rem;
}

.autocomplete-item--truncated {
  max-width: 42rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 737px) {
  .autocomplete-item--truncated {
    max-width: 80vw;
  }
}

.react-autosuggest__suggestions-container {
  background:#fff;
  left: 0;
  right: 0;
  max-height:20em;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  border: 1px solid #3e0f48;
  padding:0.25em 0.5em;
  list-style-type: none;
  color:black;
}


.style_formField__29eUo {
  padding: 12px;
  background-color: white;
  border: 1px solid rgba(62, 15, 72, 1);
  border-radius: 6px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
}

.style_formField__29eUo *:disabled {
  pointer-events: none;
}


.styles_checkboxInput__1DgdL {
  position: absolute;
  opacity: 0;
}


.pagination-link {
  color: #3E0F47 !important;
  border: 1px solid #3E0F47;
  font-size: 18px;
  line-height: 24px;
}

.pagination-link:hover {
  border: 1px solid #3E0F47;
}

.pagination-link.is-current {
  background: #FFB000;
  border: 1px solid #FFB000;
}


.styles_checkboxInput__1cTMc {
  position: absolute;
  opacity: 0;
}


.styles_checkboxGroup__lyRby {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for rows in mobile view */
  grid-gap: 16px;
  gap: 16px; /* Add spacing between checkboxes */
}

@media (min-width: 768px) {
  /* Desktop view: Stack options in a column */
  .styles_checkbox-group__3RAQJ {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the column */
  }
}

@media (max-width: 767px) {
  /* Mobile view: Arrange options in a row */
  .styles_checkbox-group__3RAQJ {
    flex-direction: row;
    justify-content: flex-start; 
  }
}

.styles_greyedOut__UL39k {
  opacity: 0.2;
  pointer-events: none; /* Prevent interaction with the checkbox */
}

.text-input {
  flex: 1 1;
  border: 1px solid #000000;
  border-radius: 6px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
  padding: 6px 12px;
}

.text-input :disabled {
  pointer-events: none;
}

.upload-icon-container {
  position: relative;
}

.logo-container {
  position: relative;
  display: inline-block;
  max-height: 250px;
}

.logo-container:hover img {
  transition: .5s ease;
  opacity: 0.3;
}

.logo-container:hover .logo-upload-icon {
  opacity: 0.8;
  transition: 1s ease;
}

.logo-upload-icon {
  width: 96px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.logo-preview {
  max-height: 250px;
  max-width: 100%;
}

.logo-success-container-hidden {
  opacity: 0;
  transition: all 500ms linear 2s;
}

.logo-success-container-shown {
  opacity: 1;
  transition: all 500ms linear;
}

.centered {
  text-align: center;
}

.black {
  color: #000000
}

.full-width {
  width: 100%;
}

.popup-overlay {  background: rgba(0, 0, 0, 0.5);}

.image-upload-modal-subheading {
  text-align: center;
}

