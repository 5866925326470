.checkboxGroup {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for rows in mobile view */
  gap: 16px; /* Add spacing between checkboxes */
}

@media (min-width: 768px) {
  /* Desktop view: Stack options in a column */
  .checkbox-group {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the column */
  }
}

@media (max-width: 767px) {
  /* Mobile view: Arrange options in a row */
  .checkbox-group {
    flex-direction: row;
    justify-content: flex-start; 
  }
}

.greyedOut {
  opacity: 0.2;
  pointer-events: none; /* Prevent interaction with the checkbox */
}